class Application {
    constructor() {
    }

    slider() {// Slider
        const self = this;
        const slider = $(".slider");
        let slidees = slider.find('.slider__slidees');

        slidees.on('init', function (event, slick, direction) {
            slider.find('.slider__slidee').height(slider.height());
        });
        slidees.on('breakpoint', function (event, slick, breakpoint) {
            slider.find('.slider__slidee').height(slider.height());
            self.imagePostition(slider.find('.slider__slidee img'), slider);
        });

        slidees.slick({
            infinite: true,
            // slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: false,
            arrows: true,
            mobileFirst: true,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 375,
                slidesToShow: 1
            }]
        });

        slider.imagesLoaded()
            .done(function (instance) {
                let images = [];
                for (let i = 0; i < instance.images.length; i++) {
                    images.push(instance.images[i].img);
                }
                self.imagePostition(images, slider);
            });
    }

    imagePostition(images, slider) {
        const sliderHeight = slider.height();
        for (let i = 0; i < images.length; i++) {
            let img = $(images[i]);
            let imgHeight = img.height();
            let imgWidth = img.width();

            if (imgHeight < imgWidth) {
                let headlineHeight = img.prev('.slider__headline').height();
                let margin = (sliderHeight / 2) - (img.height() / 2) - headlineHeight;
                img.css('margin-top', margin);
            }
        }
    }

    footnotes() {
        // Footnotes
        const footnotes = $('.layout__footnotes').hide();
        let footnotesLinks = $('.goto-link');
        footnotesLinks.click(function () {
            const link = $(this);

            footnotes.slideDown(400, function () {
                let anchor = $(link.attr('href'));
                if (anchor && anchor.length) {
                    $('html, body').animate({
                        scrollTop: $(link.attr('href')).offset().top
                    }, 1000);
                    return false;
                }
            });

            return false;
        });

        $('.scroll-to-top').click(function () {
            footnotes.slideUp(400, function () {
                $('html, body').animate({
                    scrollTop: 0
                }, 1000);
            });
            return false;
        });
    }
}

(function ($) {
    $(document).ready(function () {
        let application = new Application();
        application.slider();
        application.footnotes();
    });
})(jQuery);
